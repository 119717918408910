<template>
  <CRow>
    <CCol col="12" xl="12">
      <CCard>
        <CCardHeader>
          <div class="row">
            <div class="col-8">Kargo Firmaları</div>
            <div class="col-4 text-right">
              <CButton
                color="success"
                square
                size="sm"
                @click="openModalEvent('create', 'cargoForm')"
              >
                Kargo Firması Ekle
              </CButton>
            </div>
          </div>
        </CCardHeader>
        <CCardBody>
          <CDataTable
            :items="shippingCompanies"
            :loading="loading"
            :fields="fields"
            column-filter
            :items-per-page="10"
            hover
            pagination
            clickable-rows
            v-on:refresh="getShippingCompaniesDetail"
          >
            <template #activeFlag="{item}">
              <td>
                <CIcon
                  v-if="item.activeFlag > 0"
                  class="green"
                  name="cil-check-alt"
                />
                <CIcon v-else name="cil-x" class="red" />
              </td>
            </template>
            <template #defaultFlag="{item}">
              <td>
                <CIcon
                  v-if="item.defaultFlag > 0"
                  class="green"
                  name="cil-check-alt"
                />
                <CIcon v-else name="cil-x" class="red" />
              </td>
            </template>
            <template #show_details="{item}">
              <td class="py-2">
                <CDropdown
                  toggler-text="İşlemler"
                  class="m-2"
                  color="info"
                  size="sm"
                >
                  <CDropdownItem @click="openModalEvent('update', 'cargoForm', item, 'Düzenle')">
                    Düzenle
                  </CDropdownItem>
                </CDropdown>
              </td>
            </template>
          </CDataTable>
        </CCardBody>
      </CCard>
    </CCol>

    <ConfirmModal
      v-if="form == 'confirm'" 
      :show="openModal" 
      :title="modalTitle" 
      :openModalEvent="openModalEvent"
      :confirm="crudShippingCompany"
      :desc="modalDesc"
      :data="modalProps"
      :actionType="actionType"
      :loading="loading"
    />

    <FormModal 
      v-else
      :show="openModal" 
      :title="modalTitle" 
      :openModalEvent="openModalEvent"
      :operationEvent="crudShippingCompany"
      :actionType="actionType"
      :module="module"
      :form="form"
      :data="modalProps"
    />

    
  </CRow>
</template>

<script>

/**
 * <CDropdownItem @click="openModalEvent('destroy','','confirm',item,'Sil', item.name + ' isimli kaydı silmek istediğinize emin misiniz?')">
                    Sil
                  </CDropdownItem>
 */
import FormModal from '../components/formModal'
import ConfirmModal from '../components/confirmModal'

export default {
  name: 'Cargos',
  data() {
    return {
      shippingCompany: {},
      modalTitle: '',
      actionType: '',
      module: 'operation',
      form: 'cargoForm',
      modalProps: {},
      modalDesc: '',
      openModal: false,
      fields: [
        {
          key: 'show_details',
          label: 'İşlemler',
          _style: 'min-width:1%'
        },
        { key: 'id' },
        { key: 'name', label: 'Company Name' },
        { key: 'activeFlag', label: 'Active' },
        { key: 'webSiteUrl', label: 'Website' },
        { key: 'defaultFlag', label: 'Default' },
        { key: 'abbr', label: 'ABBR' },
        { key: 'startWeekDay', label: 'Delivery Start Day' },
        { key: 'endWeekDay', label: 'Delivery End Day' },
        { key: 'startHour', label: 'Delivery Start Hour' },
        { key: 'endHour', label: 'Delivery End Hour' }
      ],
      userRoleId: 0,
    }
  },
  components:{
    FormModal, ConfirmModal
  },
  watch: {
    $route: {
      immediate: true,
      handler(route) {
        if (route.query && route.query.shippingCompany) {
          this.activePage = Number(route.query.page)
        }
      }
    }
  },
  computed: {
    shippingCompanies() {
      let data = this.$store.getters.shippingCompanies

      data.forEach(function(o) {
        Object.keys(o).forEach(function(key) {
          o[key] === null ? (o[key] = '') : (o[key] = o[key])
        })
      })

      return data
    },
    
    loading() {
      return this.$store.getters.operationLoading
    }
  },
  methods: {
    openModalEvent(actionType, form, data, title, desc){
      this.openModal = !this.openModal
      this.actionType = actionType
      this.form = form
      this.modalProps = data
      this.modalTitle = title
      this.modalDesc = desc
    },

    async getShippingCompaniesDetail() {
      await this.$store.dispatch('shippingCompanies_list')
    },

    async crudShippingCompany(item, actionType) {

      let formData = new FormData()

      formData.append('id', item.id ? item.id : '')
      formData.append('actionType', actionType)
      formData.append('activeFlag', item.activeFlag ? item.activeFlag : false)
      formData.append('contactPerson', item.contactPerson ? item.contactPerson : false)
      formData.append('contactPhoneNumber', item.contactPhoneNumber ? item.contactPhoneNumber : false)
      formData.append('name', item.name ? item.name : '')
      formData.append('webSiteUrl', item.webSiteUrl ? item.webSiteUrl : '')
      formData.append('apiCustomerCode', item.apiCustomerCode ? item.apiCustomerCode : '')
      formData.append('apiPassword', item.apiPassword ? item.apiPassword : '')
      formData.append('apiType', item.apiType ? item.apiType : '')
      formData.append('apiUserName', item.apiUserName ? item.apiUserName : '')
      formData.append('defaultFlag', item.defaultFlag ? item.defaultFlag : false)
      formData.append('abbr', item.abbr ? item.abbr : '')
      formData.append('endHour', item.endHour ? item.endHour : '')
      formData.append('endWeekDay', item.endWeekDay ? item.endWeekDay : '')
      formData.append('startHour', item.startHour ? item.startHour : '')
      formData.append('startWeekDay', item.startWeekDay ? item.startWeekDay : '')

      await this.$store.dispatch('shippingCompanies_action', formData)

      if(this.$store.getters.operationStatus.success){
        await this.getShippingCompaniesDetail()
        await this.openModalEvent()
      }
      
    }
  },

  mounted() {
    this.getShippingCompaniesDetail()
  }
}
</script>
